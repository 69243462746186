import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const application = Application.start();
window.Stimulus = application;

const context = require.context(".", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

import PageController from "../../../../components/pages/page_controller";
application.register("page", PageController);

import SidePeekController from "../../../../components/side_peek/controller";
application.register("side-peek", SidePeekController);

import ChartController from "../../../../components/chart/controller";
application.register("chart", ChartController);

import DatasetChartController from "../../../../components/card/dataset_chart/controller";
application.register("dataset-chart", DatasetChartController);

import MainFilterController from "../../../../components/page/main_filter/controller";
application.register("main-filter", MainFilterController);

import RenUnavailabilitiesSummaryController from "../../../../components/ren/unavailabilities/summary/controller";
application.register("ren-unavailabilities--summary", RenUnavailabilitiesSummaryController);

import RenUnavailabilitiesChartController from "../../../../components/ren/unavailabilities/chart/controller";
application.register("ren-unavailabilities--chart", RenUnavailabilitiesChartController);

import RenUnavailabilitiesNewDetailsRenChildDeviceController from "../../../../components/ren/unavailabilities/new/availability/ren_child_device_controller";
application.register("ren-unavailabilities--new--details--ren-child-device", RenUnavailabilitiesNewDetailsRenChildDeviceController);

import RenUnavailabilitiesFormController from "../../../../components/ren/unavailabilities/form_controller";
application.register("ren-unavailabilities--form", RenUnavailabilitiesFormController);

import RenUnavailabilitiesPeriodController from "../../../../components/ren/unavailabilities/period_controller";
application.register("ren-unavailabilities--period", RenUnavailabilitiesPeriodController);

import RenUnavailabilitiesImportController from "../../../../components/ren/unavailabilities/import/controller";
application.register("ren-unavailabilities--import", RenUnavailabilitiesImportController);

import RenUnavailabilitiesMainFilterController from "../../../../components/ren/unavailabilities/main_filter/controller";
application.register("ren-unavailabilities--main-filter", RenUnavailabilitiesMainFilterController);

import ControlCenterMainFilterController from "../../../../components/ren/spain/control_center/main_filter/controller";
application.register("control-center--main-filter", ControlCenterMainFilterController);

import ControlCenterKpisController from "../../../../components/ren/spain/control_center/kpis/controller";
application.register("control-center--kpis", ControlCenterKpisController);

import DateRangePickerController from "../../../../components/date_range_picker/controller";
application.register("date-range-picker", DateRangePickerController);

import DatePickerController from "../../../../components/date_picker/controller";
application.register("date-picker", DatePickerController);

import MonthPickerController from "../../../../components/month_picker/controller";
application.register("month-picker", MonthPickerController);

import LineChartController from "../../../../components/line_chart/controller";
application.register("line-chart", LineChartController);

import BarChartController from "../../../../components/bar_chart/controller";
application.register("bar-chart", BarChartController);

import DoubleAxisBarChartController from "../../../../components/double_axis_bar_chart/controller";
application.register("double-axis-bar-chart", DoubleAxisBarChartController);

import FlexPerformanceChartController from "../../../../components/flex/performance/chart/controller";
application.register("flex-performance--chart", FlexPerformanceChartController);

import FlexPerformanceRankingController from "../../../../components/flex/performance/ranking/controller";
application.register("flex-performance--ranking", FlexPerformanceRankingController);

import FlexPerformanceMainFilterController from "../../../../components/flex/performance/main_filter/controller";
application.register("flex-performance--main-filter", FlexPerformanceMainFilterController);

import MainSiteSelectorController from "../../../../components/main_site_selector/controller";
application.register("main-site-selector", MainSiteSelectorController);

import MainClientSelectorController from "../../../../components/main_client_selector/controller";
application.register("main-client-selector", MainClientSelectorController);

import FlexAnnualDeliveriesMainFilterController from "../../../../components/flex/annual_deliveries/main_filter/controller";
application.register("flex-annual-deliveries--main-filter", FlexAnnualDeliveriesMainFilterController);

// import PerformanceAlertsMainFilterController from "../../../../components/ren_performance_alerts/main_filter/controller";
// application.register("performance-alerts--main-filter", PerformanceAlertsMainFilterController);

import ProgramsAndMeasurementsChartController from "../../../../components/ren/spain/programs_and_measurements/chart/controller";
application.register("programs-and-measurements--chart", ProgramsAndMeasurementsChartController);

import ProgramsAndMeasurementsMainFilterController from "../../../../components/ren/spain/programs_and_measurements/main_filter/controller";
application.register("programs-and-measurements--main-filter", ProgramsAndMeasurementsMainFilterController);

import ProgramsAndMeasurementsTableController from "../../../../components/ren/spain/programs_and_measurements/table/controller";
application.register("programs-and-measurements--table", ProgramsAndMeasurementsTableController);

import ProgramsAndMeasurementsSelectListController from "../../../../components/ren/spain/programs_and_measurements/select_list/controller";
application.register("programs-and-measurements--select-list", ProgramsAndMeasurementsSelectListController);

import FlexCollectionFormsFormController from "../../../../components/flex/collection_forms/form/controller";
application.register("flex-collection-forms--form", FlexCollectionFormsFormController);

import FlexCollectionFormsSitePartController from "../../../../components/flex/collection_forms/form/site_part/controller";
application.register("flex-collection-forms--site-part", FlexCollectionFormsSitePartController);

import FlexCollectionFormsDayAttributesController from "../../../../components/flex/collection_forms/form/day_attributes/controller";
application.register("flex-collection-forms--day-attributes", FlexCollectionFormsDayAttributesController);

import PaginationController from "../../../../components/pagination/controller";
application.register("pagination", PaginationController);

import TestPolicyProfileController from "../../../../components/navbar/test_policy_profile/controller";
application.register("test-policy-profile", TestPolicyProfileController);

import SpainSettlementTableController from "../../../../components/ren/spain/settlements/table/controller";
application.register("spain-settlement--table", SpainSettlementTableController);

import CalendarController from "../../../../components/ren/acoustic_campaigns/calendar/controller";
application.register("calendar", CalendarController);

import DropdownController from "../../../../components/dropdown/controller.js"
application.register("dropdown", DropdownController);

import MapController from "../../../../components/map/controller.js"
application.register("map", MapController);

import RenSpainChartController from "../../../../components/ren/spain/chart/controller";
application.register("ren-spain--chart", RenSpainChartController);

import RenSpainMaintenancePlansFormController from "../../../../components/ren/spain/maintenance_plans/form/controller";
application.register("ren-spain--maintenance-plans--form", RenSpainMaintenancePlansFormController);

import RenFranceNegativePriceTrackingDayDetailsController from "../../../../components/ren/france/negative_price_tracking/calendar/day_details/controller";
application.register("ren-france--negative-price-tracking-day-details", RenFranceNegativePriceTrackingDayDetailsController);

import RenFrancePdoDashboardMapController from "../../../../components/ren/france/pdo_dashboard/map/controller";
application.register("ren-france--pdo-dashboard-map", RenFrancePdoDashboardMapController);

import FlexLoadShiftsMultiSiteSelector from "../../../../components/flex/load_shifts/multi_site_selector/controller";
application.register("flex-load-shifts--multi-site-selector", FlexLoadShiftsMultiSiteSelector);

import FlexLoadShiftsLoadShiftSelector from "../../../../components/flex/load_shifts/load_shift_selector/controller";
application.register("flex-load-shifts--load-shift-selector", FlexLoadShiftsLoadShiftSelector);

import GridController from "../../../../components/sheet/controller";
application.register("sheet", GridController);
